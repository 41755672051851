/* open-sans-300normal - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local('Open Sans Light '),
    local('Open Sans-Light'),
    url('./files/open-sans-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/open-sans-latin-300.woff') format('woff'); /* Modern Browsers */
}

/* open-sans-300italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src:
    local('Open Sans Light italic'),
    local('Open Sans-Lightitalic'),
    url('./files/open-sans-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/open-sans-latin-300italic.woff') format('woff'); /* Modern Browsers */
}

/* open-sans-400normal - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Open Sans Regular '),
    local('Open Sans-Regular'),
    url('./files/open-sans-latin-400.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/open-sans-latin-400.woff') format('woff'); /* Modern Browsers */
}

/* open-sans-400italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
    local('Open Sans Regular italic'),
    local('Open Sans-Regularitalic'),
    url('./files/open-sans-latin-400italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/open-sans-latin-400italic.woff') format('woff'); /* Modern Browsers */
}

/* open-sans-600normal - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src:
    local('Open Sans SemiBold '),
    local('Open Sans-SemiBold'),
    url('./files/open-sans-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/open-sans-latin-600.woff') format('woff'); /* Modern Browsers */
}

/* open-sans-600italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src:
    local('Open Sans SemiBold italic'),
    local('Open Sans-SemiBolditalic'),
    url('./files/open-sans-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/open-sans-latin-600italic.woff') format('woff'); /* Modern Browsers */
}

/* open-sans-700normal - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Open Sans Bold '),
    local('Open Sans-Bold'),
    url('./files/open-sans-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/open-sans-latin-700.woff') format('woff'); /* Modern Browsers */
}

/* open-sans-700italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
    local('Open Sans Bold italic'),
    local('Open Sans-Bolditalic'),
    url('./files/open-sans-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/open-sans-latin-700italic.woff') format('woff'); /* Modern Browsers */
}

/* open-sans-800normal - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src:
    local('Open Sans ExtraBold '),
    local('Open Sans-ExtraBold'),
    url('./files/open-sans-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/open-sans-latin-800.woff') format('woff'); /* Modern Browsers */
}

/* open-sans-800italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 800;
  src:
    local('Open Sans ExtraBold italic'),
    local('Open Sans-ExtraBolditalic'),
    url('./files/open-sans-latin-800italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/open-sans-latin-800italic.woff') format('woff'); /* Modern Browsers */
}

